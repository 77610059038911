import { Box, Button, CircularProgress, Divider, FormControl, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UnlistedScreenerItemBox } from "../UnlistedScreener/UnlistedScrennerItemBox";
import { getUnlistedCompaniesFromUrl } from "../../services/CompanyService";

interface IProps {
    metricType: "ViewedCompanies" | "UnlockedCompanies" | "CompaniesWithFinancial";
    header: string;
}

const searchUrl = {
    "ViewedCompanies": "servicerequest/activity/viewedcompany",
    "UnlockedCompanies": "servicerequest/unlocked-companies",
    "CompaniesWithFinancial": "unlisted/companieswithfinadata/dashboard"
}

export function UnlistedCompanyMetrics({ metricType, header }: IProps) {

    const [isCompanyListLoading, setCompanyListLoading] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [currentPageSize, setCurrentPageSize] = useState(20);
    const [pageNo, setPageNo] = useState(0);

    const [noCompanyFound, setNoCompanyFound] = useState(false);

    const [companyList, setCompanyList] = useState<{
        unlocked: boolean;
        companyName: string;
        companyStatus: string;
        registeredState: string;
        // registeredOfficeAddress: string;
        principalBusinessActivity: string;
        profile_photo: string;
        paidUpCapital: Number | undefined | null;
        authorizedCapital: Number | undefined | null;
        year: Number | undefined | null;
        net_worth: Number | undefined | null;
        total_income: Number | undefined | null;
        total_expense: Number | undefined | null;
        pbt: Number | undefined | null;
        income_tax: Number | undefined | null;
        pat: Number | undefined | null;
        _id: Number | undefined | null;
    }[]>();

    const [totalRecords, setTotalRecords] = useState(0);


    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize); // Update pageSize state variable
        setPageNo(0); // Reset page number to 0
        setCurrentPageSize(pageSize); // Update current page size
    };

    useEffect(() => {
        performSearch();
    }, [pageSize, pageNo]);

    const performSearch = async () => {
        setCompanyListLoading(true);
        setCompanyList([])
        setNoCompanyFound(false);
        const response = await getUnlistedCompaniesFromUrl(searchUrl[metricType], pageNo + 1, pageSize);
        const data = await response?.companies || [];
        const total_records = response?.total_records;
        setCompanyListLoading(false);
        setCompanyList(data);
        setTotalRecords(total_records);

        if (data.length == 0) {
            setNoCompanyFound(true);
        }
    }

    const renderCompanyList = () => {
        return (
            <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap", gap: "20px" }}>
                {companyList && companyList.length > 0 && companyList.map(x => (
                    <UnlistedScreenerItemBox data={x} onClick={() => {

                    }} />
                ))
                }
            </Box>
        );
    };

    return <Box sx={{ minWidth: "calc(100% - 350px)" }}>
        <Typography sx={{ fontWeight: 700, marginBottom: "20px" }} variant="h5">{header}</Typography>
        <Divider sx={{ borderBottomWidth: "3px", marginBottom: "20px" }} variant="fullWidth" />
        <Box display={"flex"} sx={{ flexDirection: { md: "row", xs: "column" }, paddingY: "10px", border: { md: "1px solid #EEF0F4", xs: "2px solid #EEF0F4" }, borderRadius: "4px", marginBottom: "15px", paddingLeft: "20px" }}>
            <Box sx={{ textAlign: { md: "left", xs: "center" } }}>
                <FormControl sx={{ width: { xs: "80%", md: "auto" } }}>
                    <Select
                        displayEmpty
                        value={currentPageSize || ''}
                        // onChange={(e) => { setPageSize(parseInt(e.target.value as string)) }}
                        onChange={(e) => handlePageSizeChange(parseInt(e.target.value as string))}
                        input={<OutlinedInput />}
                        sx={{ fontSize: { md: "12px", xs: "14px" }, height: { xs: "40px", md: "30px" }, border: "0px" }}

                        MenuProps={{ PaperProps: { style: { fontSize: "12px" } } }}

                    >
                        <MenuItem
                            key={20}
                            value={20}
                            style={{ fontSize: "12px" }}
                        >
                            20 companies/page
                        </MenuItem>
                        <MenuItem
                            key={50}
                            value={50}
                            style={{ fontSize: "12px" }}
                        >
                            50 companies/page
                        </MenuItem>
                        <MenuItem
                            key={100}
                            value={100}
                            style={{ fontSize: "12px" }}
                        >
                            100 companies/page
                        </MenuItem>

                    </Select>
                </FormControl>
                {companyList && companyList.length > 0 && <Typography component={"p"} sx={{ fontFamily: 'Oxygen', color: "grey", fontSize: "10px", margin: { md: "5px 0px 0px 15px", xs: "5px 0px 0px 0px" } }}>{pageNo * pageSize + companyList.length} out of {totalRecords}</Typography>}
            </Box>
            <Box sx={{
                marginLeft: "auto", marginRight: { xs: "auto", md: "0px" }, "& button": {
                    backgroundColor: "transparent",
                    borderRadius: "0px",
                    boxShadow: "none",
                    padding: "0px",
                    "&:disabled": {
                        backgroundColor: "transparent",
                        opacity: 0.5
                    },
                    "&:hover": {
                        backgroundColor: "transparent"
                    }
                },
                paddingTop: companyList && companyList.length > 0 ? "8px" : "auto"
            }}>
                <Button variant="contained" disabled={pageNo <= 0} onClick={() => { setPageNo(pageNo - 1) }}><img src="/LeftButton.png" /></Button>
                <Button variant="contained" disabled={companyList && (companyList.length < pageSize)} onClick={() => { setPageNo(pageNo + 1) }}><img src="/RightButton.png" /></Button>
            </Box>
        </Box>
        {isCompanyListLoading && <CircularProgress sx={{ position: "fixed", left: "50%", top: "20%" }} color="primary" />}
        {noCompanyFound && <Typography style={{
            marginTop: "100px",
            "fontSize": "20px",
            "textAlign": "center"
        }} component={"h3"}> No Records Found.</Typography>}
        {renderCompanyList()}
    </Box>
}