import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IGrid {
    name?: string;
    title: string;
    columns: {
        name: string,
        title: string,
        minWidth?: string,
        formatter?: (obj: string) => string
    }[];
    data: any[];
}
export function StockKnocksGrid(props: IGrid) {

    useEffect(()=>{
        if(props.name && window.location.hash==`#${props.name}`){
            document.getElementById(props.name)?.scrollIntoView();
        }
    },[]);

    const separator = useMemo(() => (<span style={{ marginLeft: "auto", background: "#85858580", width: "1px", height: "25px", marginTop: "auto", marginBottom: "auto" }}></span>), []);
    return <Box id={props.name} sx={{
        background: '#F1F9F6',
        borderRadius: "10px",
        padding: { md: "30px 50px", xs: "25px 10px" },
        "& table": {
            width: "100%"
        },
        "& table thead tr": {
            borderBottom: '2px solid #009262'
        },
        "& table th": {
            color: "#000",
            fontSize: "15px",
            fontWeight: 700,
            padding: '10px 0px'
        },
        "& table, & table tr": {
            borderTop: '1px solid #858585',
            borderBottom: '1px solid #858585',
            borderCollapse: 'collapse'
        },
        "& table tr td": {
            padding: "8px 0px",
            fontSize: "13px",
            "& span": {
                fontFamily: 'Oxygen'
            }
        }
    }}>
        <Box sx={{ display: "flex", marginBottom: "20px" }}>
            <Typography sx={{ textAlign: "left", fontSize: "18px", fontWeight: 700, marginBottom: "10px" }} component={"h5"}>{props.title}</Typography>
            {props.name && <Tooltip title="Copy link" sx={{ cursor: "pointer", float: "right", marginLeft: "auto" }}>
                <IconButton
                    onClick={() => {
                        window.location.hash = props.name!;
                        navigator.clipboard.writeText(window.location.href);
                    }}
                >
                    <ContentCopyIcon
                        sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                </IconButton>
            </Tooltip>}
        </Box>
        <Box sx={{ maxHeight: "240px", overflow: "auto" }}>
            <table>
                <thead>
                    <tr>
                        {
                            props.columns.map((x, i) => (<th style={{ minWidth: x.minWidth || "auto" }}><Box sx={{ display: "flex" }}><Typography sx={{ fontWeight: 700, color: '#000', fontSize: '15px', marginLeft: "auto", marginRight: "auto", paddingX: "5px" }}> {x.title}</Typography>{i != props.columns.length - 1 && separator}</Box></th>))
                        }
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(row => (
                        <tr>
                            {
                                props.columns.map((col, index) => (
                                    <td> <Typography sx={{ color: index == 0 ? '#009262' : '#000', fontSize: "14px" }}>{col.formatter ? col.formatter(row[col.name]) : row[col.name]}</Typography></td>
                                ))
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    </Box>
}