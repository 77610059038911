import axios, { AxiosResponse } from "axios";
import { ICompaniesState } from "../model/AppState";
import { Company, CompaniesResult, OwnerData } from "../model/CompaniesResult";
import { CompanyDetailsPageResult } from "../model/CompanyDetailsPageResult";
import { DomesticIndex, DomesticIndexResult } from "../model/DomesticIndex";
import { AdvanceDeclineIndex, AdvanceDeclineIndexResult } from "../model/AdvanceDeclineIndex";
import { AdvanceDeclineDetails, AdvanceDeclineDetailsResult } from "../model/AdvanceDeclineDetails";
import { BlockDealIndex, BlockDealIndexResult } from "../model/BlockDealIndex";
import { ExchangeHolidaysIndex, ExchangeHolidaysIndexResult } from "../model/ExchangeHolidaysIndex";
import { TopGainerIndex, TopGainerIndexResult } from "../model/TopGainersIndex";
import { TopLoserIndex, TopLoserIndexResult } from "../model/TopLosersIndex";
import { BestPerformerIPO, BestPerformerIPOResult } from "../model/BestPerformerIPO"
import { ForthComingIPO, ForthComingIPOResult } from "../model/ForthComingIPO";
import { OpenIPO, OpenIPOResult } from "../model/OpenIPO";
import { FIIIndexEquityResult, FIIIndexDebtResult } from "../model/FII_Investment";
import { ClosedIPO, ClosedIPOResult } from "../model/ClosedIPO";
import { NewListingIPO, NewListingIPOResult } from "../model/NewListingIPO";
import { CompanyHistoricalData } from "../model/HistoricalDataModel";
import { BseCompaniesList } from "../model/BseCompaniesList"
import { InternationalIndex, InternationalIndexResult } from "../model/InternationalIndex";
import { ListedHomeResult } from "../model/ListedHomeResult";
import { ListedSummary, UnListedSummary } from "../model/ListedSummary";
import { SectorIndex, SectorIndexResult } from "../model/SectorIndex";
import { UnlistedCompany } from "../model/UnlistedCompaniesResult";
import { UnlistedCompanyBasicDetails, UnlistedCompanyInstaSummary, UnlistedCompanyInstaSummaryResponse, UnlistedCompanyPrice } from "../model/UnlistedCompanyBasicDetails";
import { CompanyShareholdersInfo, CompanyShareholdersResult } from "../model/CompanyShareholdersResult";
import { Actions } from "../redux/Actions";
import { store } from "../redux/store";
import { DateExtensions } from "../utility/DateExtensions";
import { ShareHoldingPatternInfo, ShareHoldingPatternResult } from "../model/ShareHoldingPatternsResult";
import { BoardOfDirectorsResult, Director } from "../model/BoardOfDirectorsResult";
import { Bulk, BulkResult } from "../model/BulkResult";
import { Block, BlockResult } from "../model/BlockResult";
import { InsiderTrading, InsiderTradingResult } from "../model/InsiderTradingResult";
import { CorporateActionsResult } from "../model/CorporateActionsResult";
import { GroupMasterData, GroupMasterDataResult } from "../model/GroupMaterData";
import { DomesticCompaniesGroup, DomesticCompaniesGroupResult } from "../model/DomesticCompaniesGroup";
import { SectorWiseCompany, SectorWiseCompanyResult } from "../model/SectorWistCompanies";
import { cloudFunctionService, companyService, marketplaceService, userImpressionService } from "../appConfig/AxiosConfig";
import { UnlistedFinanceSummary } from "../model/UnlistedFinanceSummaryResponseModel";
import { CompanyPeerDataResponse } from "../model/CompanyPeerDataModel";
import { CompanyIndividualSummary } from "../model/CompanyIndividualSummaryModel";
import { NiftyVsNiftyFuturesData } from "../model/NiftyVsNiftyFutures";
import { ScoreBoard } from "../model/ScoreBoardModel";
import { QuoteDetails } from "../model/QuoteDetails";
import { MostActiveCompaniesResult } from "../model/MostActiveCompanies";
import { WeekHighLowResult } from "../model/52WeekHighLow";
import { LowPriceHighVolumeResult } from "../model/LowPriceHighVolume";
import { DraftListingProspectus, DraftListingProspectusResult } from "../model/DraftListingProspectus";
import { State, StateList } from "../model/StateListResult";
import { UnlistedCompanyList } from "../model/StateWiseCompanies";
import { UnlistedCompaniesExploreDataResult } from "../model/UnlistedCompaniesExploreData";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const getCompanyServiceclient = (): AxiosInstance => {
//     let companyServiceclient = companyService;
//     companyServiceclient.defaults.headers.common = { "Authorization": `bearer ${Utility.getAuthToken()}`, "Content-Type": "application/x-www-form-urlencoded" };
//     return companyServiceclient;
// }

const getListedHomePageDetails = async (companyCode: string): Promise<ListedHomeResult> => {
    let response: AxiosResponse<ListedHomeResult> = await cloudFunctionService.get<any, AxiosResponse<ListedHomeResult>>("/listedhome");
    return response.data;
}

const getListedCompanyDetails = async (): Promise<BseCompaniesList> => {
    let response: AxiosResponse<BseCompaniesList> = await companyService.get<any, AxiosResponse<BseCompaniesList>>("/master/?path=ticker/BSE");
    return response.data;
}

const getCompanies = async (): Promise<Company[]> => {
    let response: AxiosResponse<CompaniesResult> = await companyService.get<any, AxiosResponse<CompaniesResult>>('/master/?path=companymaster');
    return response.data.data;
}

const getCompaniesSK = async (): Promise<Company[]> => {
    let response: AxiosResponse<Company[]> = await companyService.get<any, AxiosResponse<Company[]>>('/listed/all');
    return response.data;
}

const getUnlistedCompanyVideoForPage = async (refId): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any,
        AxiosResponse<any>>(
            `/companyvideos/list?refid=${refId}&reftype=UnlistedCompany&page=0&pagesize=50&count=true`
            // `/companyvideos/list?refid=${refId}&reftype=UnlistedCompany&page=${page}&pagesize=${pagesize}&count=true`
        );
    return response.data;
}

const getListedCompanyVideoForPage = async (refId): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any,
        AxiosResponse<any>>(
            `/companyvideos/list?refid=${refId}&reftype=Company&page=0&pagesize=50&count=true`
            // `/companyvideos/list?refid=${refId}&reftype=UnlistedCompany&page=${page}&pagesize=${pagesize}&count=true`
        );
    return response.data;
}

const getUnlistedWebsiteUrl = async (companyId: any): Promise<any> => {
    let response: AxiosResponse<any> = await userImpressionService.get<any, AxiosResponse<any>>(`/webpageconfig/website?userid=${companyId}`);
    return response.data;
}

const getListedWebsiteUrl = async (companyId: any): Promise<any> => {
    let response: AxiosResponse<any> = await userImpressionService.get<any, AxiosResponse<any>>(`/webpageconfig/website?userid=${companyId}`);
    return response.data;
}

const getCompanyUnlockRequest = async (page: number, pagesize: number, getCount: boolean): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any,
        AxiosResponse<any>>(`/servicerequest/data/list?page=${page}&pagesize=${pagesize}&count=${getCount}`);
    // AxiosResponse<any>>(`/servicerequest/data/list?type=${type}&page=${page}$pagesize=${pagesize}`);
    return response.data;
}

const putCompanyUnlockRequest = async (statusId: string, statusBody: object): Promise<[]> => {
    let response: AxiosResponse<[]> = await companyService.put<any, AxiosResponse<[]>>(`/servicerequest/unlock/${statusId}`, statusBody);
    return response.data;
}

const searchListedCompanies = async (searchTerm: string): Promise<Company[]> => {
    let response: AxiosResponse<Company[]> = await companyService.get<any, AxiosResponse<Company[]>>(`/listed/search/?term=${searchTerm}`);
    return response.data;
}

const getMultipleCompanies = async (field: string, values: string[]): Promise<Company[]> => {
    let response: AxiosResponse<Company[]> = await companyService
        .get<any, AxiosResponse<Company[]>>(`/listed/getbyvaluelist?field=${field}&values=${values.join()}`);
    return response.data;
}

const getCompany = async (companyCode: string): Promise<CompanyDetailsPageResult> => {
    let response: AxiosResponse<CompanyDetailsPageResult> = await cloudFunctionService.get<any, AxiosResponse<CompanyDetailsPageResult>>(`/companyoverviewpartone/?co_code=${companyCode}`);
    return response.data;
}

const getCompanyMeta = async (field: string, value: string): Promise<Company> => {
    let response: AxiosResponse<Company> = await companyService.get<any, AxiosResponse<Company>>(`/v2/listed/meta?field=${field}&value=${value}`);
    return response.data;
}

const getCompanyOwner = async (co_code: string): Promise<OwnerData> => {
    let response: AxiosResponse<OwnerData> = await companyService.get<any, AxiosResponse<OwnerData>>(`/v2/listed/owner/${co_code}`);
    return response.data;
}

const getUnlistedCompanySummary = async (cin: string | null): Promise<UnlistedCompanyInstaSummary> => {
    let response: AxiosResponse<UnlistedCompanyInstaSummaryResponse> = await companyService.get
        <any, AxiosResponse<UnlistedCompanyInstaSummaryResponse>>(`/unlisted/summary?cin=${cin}`);
    return response.data?.summary;
}

const getUnlistedCompanyPrice = async (cin: string | null): Promise<UnlistedCompanyPrice> => {
    let response: AxiosResponse<UnlistedCompanyPrice> = await companyService.get<any, AxiosResponse<UnlistedCompanyPrice>>(`/unlisted/price?cin=${cin}`);
    return response.data;
}

const getUnlistedCompanySeo = async (code: string) => {
    return (await marketplaceService.get(`/webpageconfig/page/meta?userid=${code}`)).data.meta_title;
}

const getCorporateActions = async (companyCode: string | number | null): Promise<CorporateActionsResult> => {
    let response: AxiosResponse<CorporateActionsResult> = await cloudFunctionService.get<any, AxiosResponse<CorporateActionsResult>>(`/companykeyevents/?co_code=${companyCode}`);
    return response.data;
}

const getScoreBoard = async (companyCode: string | number): Promise<ScoreBoard | null> => {
    try {
        let response: AxiosResponse<any> = await companyService
            .get<any, AxiosResponse<any>>(`master/?path=ScoreBoard/${companyCode}`);
        return response.data.data[0];
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

const getHistoricalData = async (exchange: string, companyCode: string | number, fromData: Date, todate: Date): Promise<CompanyHistoricalData> => {
    let response: AxiosResponse<any> = await companyService
        .get<any, AxiosResponse<CompanyHistoricalData>>(`master/?path=CompanyPriceHistorical/${exchange}/${companyCode}/${DateExtensions.getDateString(fromData)}/${DateExtensions.getDateString(todate)}`);
    return response.data.data;
}

const getQuarterlyResults = async (companyCode: string, type: string,): Promise<any> => {
    let response: AxiosResponse<any> = await companyService
        .get<any, AxiosResponse<any>>(`listedfinancials/quarterlyresults/${companyCode}/${type}`);
    return response.data.data;
}

const getBoardMeetings = async (companyCode: string): Promise<any> => {
    let response: AxiosResponse<any> = await companyService
        .get<any, AxiosResponse<any>>(`master/?path=Board-Meetings/${companyCode}/10`);
    return response.data.data;
}

const getPLStatement = async (companyCode: string, type: string,): Promise<any> => {
    let response: AxiosResponse<any> = await companyService
        .get<any, AxiosResponse<any>>(`listedfinancials/profitandloss/${companyCode}/${type}`);
    return response.data.data;
}

const getBalanceSheet = async (companyCode: string, type: string,): Promise<any> => {
    let response: AxiosResponse<any> = await companyService
        .get<any, AxiosResponse<any>>(`listedfinancials/balancesheet/${companyCode}/${type}`);
    return response.data.data;
}

const getCashFlowData = async (companyCode: string, type: string,): Promise<any> => {
    let response: AxiosResponse<any> = await companyService
        .get<any, AxiosResponse<any>>(`listedfinancials/cashflow/${companyCode}/${type}`);
    return response.data.data;
}

const getQuoteDetails = async (companyCode: string, exchange: string,): Promise<QuoteDetails | null> => {
    try {
        let response: AxiosResponse<any> = await companyService
            .get<any, AxiosResponse<any>>(`master/?path=GetQuoteDetails/${companyCode}/${exchange}`);
        return response.data.data[0];
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

const getShareHoldingPattern = async (companyCode: string): Promise<ShareHoldingPatternInfo[]> => {
    let response: AxiosResponse<ShareHoldingPatternResult> = await companyService
        .get<any, AxiosResponse<ShareHoldingPatternResult>>(`/listedshareholdings/pattern/co_code/${companyCode}`);
    return response.data.data;
}

const getCompanyShareholdersInfo = async (companyCode: string | number): Promise<CompanyShareholdersInfo[]> => {
    // More-than-one-Per-Holding-Details
    let response: AxiosResponse<CompanyShareholdersResult> = await companyService
        .get<any, AxiosResponse<CompanyShareholdersResult>>(`/listedshareholdings/co_code/${companyCode}`);
    return response.data.data;
}

const getCompanyShareHoldingDetailsByShareHoldersName = async (name: string): Promise<CompanyShareholdersInfo[]> => {
    let response: AxiosResponse<CompanyShareholdersInfo[]> = await companyService
        .get<any, AxiosResponse<CompanyShareholdersInfo[]>>(`/listedshareholdings/name/${name}`);
    return response.data;
}

const getInvestorNames = async (): Promise<string[]> => {
    let response: AxiosResponse<string[]> = await companyService
        .get<any, AxiosResponse<string[]>>(`/listedshareholdings/investors/list`);
    return response.data;
}

const getInvestorPortfolios = async (term: string): Promise<CompanyShareholdersInfo[]> => {
    let response: AxiosResponse<CompanyShareholdersInfo[]> = await companyService
        .get<any, AxiosResponse<CompanyShareholdersInfo[]>>(`/listedshareholdings/investors/search?term=${term}`);
    return response.data;
}

const getCompanyPeerData = async (companyCode: string | number, type: 'S' | 'C', count: number): Promise<CompanyPeerDataResponse> => {
    let response: AxiosResponse<CompanyPeerDataResponse> = await cloudFunctionService.get<any, AxiosResponse<CompanyPeerDataResponse>>(`/listedpeerdata?co_code=${companyCode}&type=${type}&count=${count}`);
    return response.data;
}

const getCompanyKeyRatios = async (companyCode: string | number, type: 'S' | 'C'): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/master/?path=KeyFinancialRatios/${companyCode}/${type}`);
    return response.data.data;
}

const getBoardOfDirectors = async (companyCode: string): Promise<Director[]> => {
    let response: AxiosResponse<BoardOfDirectorsResult> = await companyService
        .get<any, AxiosResponse<BoardOfDirectorsResult>>(`listedfinancials/boardofdirectors/${companyCode}`);
    return response.data.data;
}

const getBulkDeals = async (companyCode: string, exchange: string): Promise<Bulk[]> => {
    let response: AxiosResponse<BulkResult> = await companyService.get<any, AxiosResponse<BulkResult>>(`/master/?path=BulkBlockDeal/${companyCode}/${exchange.toLowerCase()}/bulk/1000`);
    return response.data.data;
}

const getBlockDeals = async (companyCode: string, exchange: string): Promise<Block[]> => {
    let response: AxiosResponse<BlockResult> = await companyService.get<any, AxiosResponse<BlockResult>>(`/master/?path=BulkBlockDeal/${companyCode}/${exchange.toLowerCase()}/block/1000`);
    return response.data.data;
}

const getInsiderTrading = async (companyCode: string): Promise<InsiderTrading[]> => {
    var currentDate = new Date();
    var toDate = DateExtensions.getDateString(currentDate);
    currentDate.setFullYear(currentDate.getFullYear() - 10);
    currentDate.setDate(currentDate.getDate() + 2);
    var fromDate = DateExtensions.getDateString(currentDate);
    let response: AxiosResponse<InsiderTradingResult> = await companyService.get<any, AxiosResponse<InsiderTradingResult>>(`/master/?path=insidertrading/${companyCode}/ALL/${fromDate}/${toDate}/1000`);
    return response.data.data;
}

const getListedSummary = async (): Promise<ListedSummary> => {
    let response: AxiosResponse<ListedSummary> = await cloudFunctionService.get<any, AxiosResponse<ListedSummary>>(`/listedhome`);
    return response.data;
}

const loadCompaniesToAppState = async () => {
    const companies = await getCompaniesSK();
    const date = new Date();
    const payload: ICompaniesState = { lastUpdated: date, companies: companies };
    store.dispatch({ type: Actions.LOADORUPDATECOMPANIES, payload: payload });
}

const getDomesticIndexes = async (): Promise<Array<DomesticIndex>> => {
    let response: AxiosResponse<DomesticIndexResult> = await companyService.get<any, AxiosResponse<DomesticIndexResult>>('/master/?path=Indices');
    return response.data.data;
}

const getInternationIndexes = async (): Promise<Array<InternationalIndex>> => {
    let response: AxiosResponse<InternationalIndexResult> = await companyService.get<any, AxiosResponse<InternationalIndexResult>>('/master/?path=WorldIndices');
    return response.data.data;
}

const getFIIDataIndexes = async (decider: string, fiiType: string) => {
    if (decider === 'equity') {
        let response: AxiosResponse<FIIIndexEquityResult> = await companyService.get<any, AxiosResponse<FIIIndexEquityResult>>(`/master/?path=${fiiType}-Investment`);
        return response.data.data;
    } else {
        let response: AxiosResponse<FIIIndexDebtResult> = await companyService.get<any, AxiosResponse<FIIIndexDebtResult>>(`/master/?path=${fiiType}-Investment`);
        return response.data.data;
    }

}

const getSectorIndexes = async (): Promise<Array<SectorIndex>> => {
    let response: AxiosResponse<SectorIndexResult> = await companyService.get<any, AxiosResponse<SectorIndexResult>>('/master/?path=SectorList');
    return response.data.data;
}
const getLosersIndexes = async (exchange: string, group: string, duration: string): Promise<Array<TopLoserIndex>> => {
    const pathFragment = exchange === 'bse' ? `/bse/${group || 'bse_sensex'}` : `/nse/${group || 'nse'}`
    let response: AxiosResponse<TopLoserIndexResult> = await companyService.get<any, AxiosResponse<TopLoserIndexResult>>(`/master/?path=losers${pathFragment}/${duration}/10`);
    return response.data.data;
}
// 52 week high
const getWeekHighIndexes = async (exchange: string, groupName: string) => {
    const pathFragment = exchange === 'bse' ? `/bse/${groupName || "bse"}` : `/nse/${groupName || "bse"}`
    let response: AxiosResponse<WeekHighLowResult> = await companyService.get<any, AxiosResponse<WeekHighLowResult>>(`/master/?path=FiftyTwoWeekHigh${pathFragment}/10`);
    return response.data.data;
}
// 52 week low
const getWeekLowIndexes = async (exchange: string, groupName: string) => {
    const pathFragment = exchange === 'bse' ? `/bse/${groupName || "bse"}` : `/nse/${groupName || "bse"}`
    let response: AxiosResponse<WeekHighLowResult> = await companyService.get<any, AxiosResponse<WeekHighLowResult>>(`/master/?path=FiftyTwoWeekLow${pathFragment}/10`);
    return response.data.data;
}

// Arbitrage Opportunities
const getArbitrageOpportunitiesIndexes = async (exchange: string) => {
    const pathFragment = exchange === 'bse' ? 'BSE' : 'NSE'
    let response: AxiosResponse<WeekHighLowResult> = await companyService.get<any, AxiosResponse<WeekHighLowResult>>(`/master/?path=ArbitrageOpp/${pathFragment}/25`);
    return response.data.data;
}

// Announcements
const getAnnouncementsIndexes = async (exchange: string) => {
    const pathFragment = exchange === 'bse' ? 'bse' : 'nse'
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/master/?path=Announcement/${pathFragment}`);
    return response.data.data;
}

// All Time Low
const getAllTimelOWIndexes = async (exchange: string, groupName: string) => {
    const pathFragment = exchange === 'bse' ? `/bse/year/${groupName || "bse"}` : `/nse/year/${groupName || "nse"}`
    let response: AxiosResponse<WeekHighLowResult> = await companyService.get<any, AxiosResponse<WeekHighLowResult>>(`/master/?path=ALLLow${pathFragment}/10`);
    return response.data.data;
}
// All Time High
const getAllTimehighIndexes = async (exchange: string, groupName: string) => {
    const pathFragment = exchange === 'bse' ? `/bse/year/${groupName || "bse"}` : `/nse/year/${groupName || "nse"}`
    let response: AxiosResponse<WeekHighLowResult> = await companyService.get<any, AxiosResponse<WeekHighLowResult>>(`/master/?path=ALLHigh${pathFragment}/10`);
    return response.data.data;
}

const getLowPriceHighVolume = async (exchange: string, groupName: string) => {
    const pathFragment = exchange === 'bse' ? `/BSE/25/${groupName || "bse"}/` : `/NSE/25/${groupName || "nse"}/`
    let response: AxiosResponse<LowPriceHighVolumeResult> = await companyService.get<any, AxiosResponse<LowPriceHighVolumeResult>>(`/master/?path=LowPriceHighVolume${pathFragment}/10`);
    return response.data.data;
}

const getGainersIndexes = async (exchange: string, exchangeGroup: string, duration: string): Promise<Array<TopGainerIndex>> => {
    const pathFragment = exchange === 'bse' ? `/bse/${exchangeGroup || 'bse_sensex'}` : `/nse/${exchangeGroup || "nse"}`
    let response: AxiosResponse<TopGainerIndexResult> = await companyService.get<any, AxiosResponse<TopGainerIndexResult>>(`/master/?path=Gainers${pathFragment}/${duration}/10`);
    return response.data.data;
}

// Out Performer
const getOutPerformerIndexes = async (exchange: string, exchangeGroup: string, duration: string): Promise<Array<TopGainerIndex>> => {
    const pathFragment = exchange === 'bse' ? `/bse/${exchangeGroup || 'bse_sensex'}` : `/nse/${exchangeGroup || "nse"}`
    let response: AxiosResponse<TopGainerIndexResult> = await companyService.get<any,
        AxiosResponse<TopGainerIndexResult>>(`/master/?path=OutUnderPerformers${pathFragment}/out/${duration}/50`);
    return response.data.data;
}
// Under Performer
const getUnderPerformerIndexes = async (exchange: string, exchangeGroup: string, duration: string): Promise<Array<TopGainerIndex>> => {
    const pathFragment = exchange === 'bse' ? `/bse/${exchangeGroup || 'bse_sensex'}` : `/nse/${exchangeGroup || "nse"}`
    let response: AxiosResponse<TopGainerIndexResult> = await companyService.get<any,
        AxiosResponse<TopGainerIndexResult>>(`/master/?path=OutUnderPerformers${pathFragment}/under/${duration}/50`);
    return response.data.data;
}

// Heatmap
const getHeatmapIndexes = async (exchange: string, exchangeGroup: string, duration: string): Promise<Array<any>> => {
    const pathFragment = exchange === 'bse' ? `/bse/${exchangeGroup || 'bse_sensex'}` : `/nse/${exchangeGroup || "nse"}`
    let response: AxiosResponse<any> = await companyService.get<any,
        AxiosResponse<any>>(`/master/?path=HeatMap${pathFragment}/${duration}/50`);
    return response.data.data;
}

const getAdvanceDeclineIndexes = async (exchange: string): Promise<Array<AdvanceDeclineIndex>> => {
    let response: AxiosResponse<AdvanceDeclineIndexResult> = await companyService.get<any, AxiosResponse<AdvanceDeclineIndexResult>>(`/master/?path=AdvancesDeclines/${exchange}`);
    return response.data.data;
}
const getAdvanceDeclineDetails = async (exchange: string, group: string, type: 'A' | 'D' | 'Un'): Promise<Array<AdvanceDeclineDetails>> => {
    let response: AxiosResponse<AdvanceDeclineDetailsResult> = await companyService.get<any, AxiosResponse<AdvanceDeclineDetailsResult>>(`/master/?path=advancesdeclinesdetails/${exchange}/${group}/${type}`);
    return response.data.data;
}
const getExchangeHolidayIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=exchangeholidays/bse');
    return response.data.data;
}
// Change Of Name
const getChangeOfNameIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=ChangeOfName/all');
    return response.data.data;
}
// Credit Rating
const getCreditRatingIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=CreditRating');
    return response.data.data;
}

// Upcoming Events
const getUpcomingEventsIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=corp-action-WKMonth-details/mon/BookCloser/50');
    return response.data.data;
}

// Book Closer
// const getBookCloserIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
//     let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=Book-Closer-Latest/5400/all/all');
//     return response.data.data;
// }

// Forex
const getForexIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=Forex');
    return response.data.data;
}
// Delisted Shares
const getDelistedShares = async (): Promise<Array<any>> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>('/master/?path=DeListed');
    return response.data.data;
}

// MF Investment
const getMFInvestment = async (): Promise<Array<any>> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>('/master/?path=MF_Investment');
    return response.data.data;
}

// ADR Prices
const getAdrPricesIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=ADR-Prices');
    return response.data.data;
}
// ADR Master
const getAdrMasterIndexes = async (): Promise<Array<ExchangeHolidaysIndex>> => {
    let response: AxiosResponse<ExchangeHolidaysIndexResult> = await companyService.get<any, AxiosResponse<ExchangeHolidaysIndexResult>>('/master/?path=ADR-Master');
    return response.data.data;
}

const getBlockDealIndexes = async (exchange: string, type: string): Promise<Array<BlockDealIndex>> => {
    let response: AxiosResponse<BlockDealIndexResult> = await companyService.get<any, AxiosResponse<BlockDealIndexResult>>(`/master/?path=BulkBlockDeal/${exchange}/${type}/10`);
    return response.data.data;
}

const getActiveCompaniesByVolume = async (exchange: string, exchangeGroup: string) => {
    const pathFragment = exchange === 'bse' ? `/bse/${exchangeGroup || "bse_sensex"}` : `/nse/${exchangeGroup || "nse"}`
    let response: AxiosResponse<MostActiveCompaniesResult> = await companyService.get<any, AxiosResponse<MostActiveCompaniesResult>>(`/master/?path=MostActiveToppers${pathFragment}/value/10`);
    return response.data.data;
}

// Best Performing IPOs
const getBestPerformerIpo = async (exchange: "nse" | "bse"): Promise<Array<BestPerformerIPO>> => {
    let response: AxiosResponse<BestPerformerIPOResult> = await companyService.get<any, AxiosResponse<BestPerformerIPOResult>>(`/master/?path=BestPerformerIpo/${exchange}/50`);
    return response.data.data;
}
// Tradable Companies
const getTradableCompanies = async (page: number, pagesize: number, getCount: boolean): Promise<UnlistedCompaniesExploreDataResult> => {
    let response: AxiosResponse<UnlistedCompaniesExploreDataResult> = await companyService.get<any, AxiosResponse<UnlistedCompaniesExploreDataResult>>(`/unlisted/tradablecompanies?page=${page}&pagesize=${pagesize}&count=${getCount}`);
    return response.data;
}
// Companies With Fin Data
const getCompaniesWithFinData = async (page: number, pagesize: number, getCount: boolean): Promise<UnlistedCompaniesExploreDataResult> => {
    let response: AxiosResponse<UnlistedCompaniesExploreDataResult> = await companyService.get<any, AxiosResponse<UnlistedCompaniesExploreDataResult>>(`/unlisted/instafincompanies?page=${page}&pagesize=${pagesize}&count=${getCount}`);
    return response.data;
}

const getPreviewUnlistedAdvert = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    const company = await companyService.get('/unlisted/advert/companieswithfindata/new')
    return company.data
}

// State List
const getStateList = async (): Promise<State[]> => {
    let response: AxiosResponse<StateList> = await companyService.get<any, AxiosResponse<StateList>>('/unlisted/statelist');
    return response.data.States;
}

// Status List
const getStatusList = async (): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>('/unlisted/statuslist');
    return response.data.StatusList;
}


// State Wise Companies
const getStateWiseCompanies = async (stateKey: string, page: number, pagesize: number, getCount: boolean): Promise<UnlistedCompanyList> => {
    let response: AxiosResponse<UnlistedCompanyList> = await companyService.get<any, AxiosResponse<UnlistedCompanyList>>(`/unlisted/statewisecompanies?page=${page}&pagesize=${pagesize}&state=${stateKey}&count=${getCount}`);
    return response.data;
}

// Status Wise Companies
const getStatusWiseCompanies = async (statusKey: string, page: number, pagesize: number, getCount: boolean): Promise<UnlistedCompanyList> => {
    let response: AxiosResponse<UnlistedCompanyList> = await companyService.get<any, AxiosResponse<UnlistedCompanyList>>(`/unlisted/statuswisecompanies?page=${page}&pagesize=${pagesize}&status=${statusKey}&count=${getCount}`);
    return response.data;
}

const getForthComingIpo = async (exchange: "nse" | "bse"): Promise<Array<ForthComingIPO>> => {
    let response: AxiosResponse<ForthComingIPOResult> = await companyService.get<any, AxiosResponse<ForthComingIPOResult>>(`/master/?path=forthcomingipo/${exchange}/IPO/50`);
    return response.data.data;
}
const getOpenIpo = async (exchange: "nse" | "bse"): Promise<Array<OpenIPO>> => {
    let response: AxiosResponse<OpenIPOResult> = await companyService.get<any, AxiosResponse<OpenIPOResult>>(`/master/?path=OpenIssues/${exchange}/IPO/50`);
    return response.data.data;
}
const getClosedIpo = async (exchange: "nse" | "bse", startDate: string, closeDate: string): Promise<Array<ClosedIPO>> => {
    let response: AxiosResponse<ClosedIPOResult> = await companyService.get<any, AxiosResponse<ClosedIPOResult>>(`/master/?path=ClosedIssues/${exchange}/IPO/50/${startDate}/${closeDate}`);
    return response.data.data;
}
const getNewListingIpo = async (exchange: "nse" | "bse"): Promise<Array<NewListingIPO>> => {
    let response: AxiosResponse<NewListingIPOResult> = await companyService.get<any, AxiosResponse<NewListingIPOResult>>(`/master/?path=Newlisting/${exchange}/50`);
    return response.data.data;
}
const getDraftProspectus = async (): Promise<Array<DraftListingProspectus>> => {
    let response: AxiosResponse<DraftListingProspectusResult> = await companyService
        .get<any, AxiosResponse<DraftListingProspectusResult>>(`/master/?path=draftprospectus/sebi`);
    return response.data.data;
}

const getGroupMasterData = async (exchange: "NSE" | "BSE"): Promise<Array<GroupMasterData>> => {
    let response: AxiosResponse<GroupMasterDataResult> = await companyService.get<any, AxiosResponse<GroupMasterDataResult>>(`/master/?path=GroupMaster/${exchange}`);
    return response.data.data;
}

const getDomesticCompaniesByGroup = async (exchange: "NSE" | "BSE", groupName: string): Promise<Array<DomesticCompaniesGroup>> => {
    let response: AxiosResponse<DomesticCompaniesGroupResult> = await companyService.get<any, AxiosResponse<DomesticCompaniesGroupResult>>(`/master/?path=indexconstituents/${exchange}/${groupName}`);
    return response.data.data;
}

const getSectorWiseCompanies = async (sectorCode: string): Promise<Array<SectorWiseCompany>> => {
    let response: AxiosResponse<SectorWiseCompanyResult> = await companyService.get<any, AxiosResponse<SectorWiseCompanyResult>>(`/master/?path=SectorWiseComp/${sectorCode}`);
    return response.data.data;
}


const getUnListedSummary = async (): Promise<UnListedSummary> => {
    let response: AxiosResponse<UnListedSummary> = await cloudFunctionService.get<any, AxiosResponse<UnListedSummary>>(`/unlistedcompanyhome?exchange=NSE`);
    return response.data;
}

const getUnListedMinimal = async (companyName: string) => {
    let response = await companyService.get(`/unlisted/companyname/${companyName}`);
    return response.data;
}

const getUnListedFinancialsSummary = async (cin: string): Promise<UnlistedFinanceSummary> => {
    let response: AxiosResponse<UnlistedFinanceSummary> = await companyService.get<any, AxiosResponse<UnlistedFinanceSummary>>(`/unlistedcompanies/financials/${cin}`);
    return response.data;
}

const getUnListedFinancialsReports = async (cin: string): Promise<UnlistedFinanceSummary[]> => {
    let response: AxiosResponse<UnlistedFinanceSummary[]> = await companyService.get<any, AxiosResponse<UnlistedFinanceSummary[]>>(`/unlistedcompanies/financials/reports/${cin}`);
    return response.data;
}
const getListedCompanyIndividualSummary = async (excange: string, co_code: string | number): Promise<CompanyIndividualSummary> => {
    let response: AxiosResponse<CompanyIndividualSummary> = await cloudFunctionService.get<any, AxiosResponse<CompanyIndividualSummary>>(`companyhomepagev2?co_code=${co_code}&exchange=${excange}`);
    return response.data;
}

const getNiftyVsNiftyFutures = async (range: 'D1' | 'D5' | 'W2' | 'M1' | 'M3', date: Date): Promise<NiftyVsNiftyFuturesData> => {
    let response: AxiosResponse<NiftyVsNiftyFuturesData> = await companyService.get<any, AxiosResponse<NiftyVsNiftyFuturesData>>(`/master/?path=Nifty-Vs-Nifty-Futures-chart/${DateExtensions.stringifyDateYYYYMMDD(date, "-")}/${range}`);
    return response.data;
}
// Din Status
const getDinStatus = async (din: any): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/unlisted/directors/${din}`);
    return response.data;
}

// CIN Status
const getCinStatus = async (cin: any): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/unlisted/cin/${cin}`);
    return response.data;
}

// Modern Apis
const searchCompanies = async (searchTerm: string, searchType: 'Listed' | 'Unlisted'): Promise<UnlistedCompany[]> => {
    let response: AxiosResponse<UnlistedCompany[]> = await companyService.get
        <any, AxiosResponse<UnlistedCompany[]>>(`/v1/companies/${searchType.toLowerCase()}/search?term=${searchTerm}`);
    return response.data;
}

const getCompanyById = async (companyCode: string | null): Promise<UnlistedCompanyBasicDetails> => {
    let response: AxiosResponse<UnlistedCompanyBasicDetails> = await companyService.get<any, AxiosResponse<UnlistedCompanyBasicDetails>>(`/v1/companies/basicinfo/${companyCode}`);
    return response.data;
}

const getUnlistedCompaniesFromUrl = async (url: string, pageNo: any, pageSize: any): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`${url}?page=${pageNo}&pagesize=${pageSize}&count=true`);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Company PerformSearch read failed.");
    }

}

export {
    getBestPerformerIpo, getOpenIpo, getForthComingIpo, getClosedIpo, getNewListingIpo, getDraftProspectus, getListedHomePageDetails, getCompanies, loadCompaniesToAppState, getCompany, getCompanyMeta, getCompanyOwner, getListedSummary, getUnlistedCompanySummary, getUnlistedCompanyPrice, getHistoricalData,
    getCorporateActions,
    getQuarterlyResults, getPLStatement, getBalanceSheet, getCashFlowData, getQuoteDetails, getScoreBoard,
    getShareHoldingPattern, getCompanyShareholdersInfo, getCompanyShareHoldingDetailsByShareHoldersName, getInvestorNames, getInvestorPortfolios,
    getCompanyPeerData, getCompanyKeyRatios,
    getBoardOfDirectors,
    getWeekHighIndexes,
    getWeekLowIndexes,
    getListedCompanyDetails,
    getBulkDeals, getBlockDeals, getDomesticIndexes,
    getInternationIndexes,
    getCompanyUnlockRequest,
    getFIIDataIndexes,
    putCompanyUnlockRequest,
    getActiveCompaniesByVolume,
    getSectorIndexes,
    getLowPriceHighVolume,
    getInsiderTrading,
    getGroupMasterData,
    getAllTimelOWIndexes,
    getAllTimehighIndexes,
    getDomesticCompaniesByGroup,
    getSectorWiseCompanies,
    getUnListedSummary,
    getUnListedFinancialsSummary,
    getUnListedFinancialsReports,
    getLosersIndexes,
    getAdvanceDeclineIndexes,
    getAdvanceDeclineDetails,
    getBlockDealIndexes,
    getGainersIndexes,
    getOutPerformerIndexes,
    getUnderPerformerIndexes,
    getExchangeHolidayIndexes,
    getChangeOfNameIndexes,
    getCreditRatingIndexes,
    getUpcomingEventsIndexes,
    getUnListedMinimal,
    // getBookCloserIndexes,
    getForexIndexes,
    getMFInvestment,
    getArbitrageOpportunitiesIndexes,
    getAdrPricesIndexes,
    getAdrMasterIndexes,
    getListedCompanyIndividualSummary,
    getNiftyVsNiftyFutures,
    searchListedCompanies,
    getMultipleCompanies,
    getBoardMeetings,
    getStateList,
    getStateWiseCompanies,
    getTradableCompanies,
    getCompaniesWithFinData,
    getDinStatus,
    getCinStatus,
    getDelistedShares,
    getHeatmapIndexes,
    getPreviewUnlistedAdvert,
    getAnnouncementsIndexes,
    getStatusList,
    getStatusWiseCompanies,
    getUnlistedCompanyVideoForPage,
    getUnlistedWebsiteUrl,
    getListedWebsiteUrl,
    getUnlistedCompanySeo,
    getListedCompanyVideoForPage,

    searchCompanies,
    getCompanyById,
    getUnlistedCompaniesFromUrl
}